import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState,useEffect } from "react";
import { useForm  } from "react-hook-form";
import * as yup from "yup";
import { submitFormToHubspot } from "../../utils/submitFormToHubspot";
import {
  BasicElementsContainer,
  ErrorMessage,
  FormContainer,
  FormLabel,
  FormTitle,
  InputWrapper,
  PrivacyDisclaimer,
  StyledInput,
  SubmitButton,
  FormHubspotWrapper
} from "../shared/FormStyles";
import { Link } from "gatsby";


const TechSpecForm = () => {

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.head.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "6034553",
          formId: "93f03a20-3051-47fd-a82a-bda38b8b8df9",
          target: "#hubspotForm",
        });
      }
    };

    return () => {
      document.head.removeChild(script);
    }
  }, []);

  
  return (
    <FormHubspotWrapper >
      <div id="hubspotForm" ></div>
    </FormHubspotWrapper>
  );
};

export default TechSpecForm;
