import React from "react";
import TechSpecForm from "../components/basicForm/TechSpecForm";
import Layout from "../components/Layout";
import { PrivacyDisclaimer } from "../components/shared/FormStyles";
import BasicFormTemplate from "../templates/BasicFormTemplate";

const seo = {
  title: "Technical Specification",
  description:
    "Request a technical specification for The PLX-Benchtop - a mechanical testing device that generates stress/strain data in just 3 minutes. ",
};

const title = `Request a technical specification`;
const byline = `Fill in your details below to receive a technical specification for The PLX-Benchtop.`;

const RequestATechSpec = () => {
  return (
    <Layout seo={seo} backgroundColorUnderneath="white">
      <BasicFormTemplate title={title} byline={byline}>
        <TechSpecForm />
        <PrivacyDisclaimer mobile />
      </BasicFormTemplate>
    </Layout>
  );
};

export default RequestATechSpec;
